import React from 'react';

import { Grid, IconButton, Typography, Dialog, AppBar, Toolbar, Button, Card, Box, CardHeader, FormControl, CardContent, InputLabel, CardActions, TextField } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl'
import { Environment } from '../Environment'
import CloseIcon from '@mui/icons-material/Close';
import ContentContainer from '../ContentContainer/ContentContainer'
import { RoleResource, ProjectResource, IdentityResource } from '../generated/launchpad-api'
import TransferList from '../TransferList/TransferList'
import Project from '../CreateEditProject/CreateEditProject';

type CreateEditAdminProps = {
    isOpen: boolean,
    roles: RoleResource[] | undefined,
    identity: IdentityResource | undefined
    closeDialog: () => void,
    createIdentity: (name: string, password: string, roles: RoleResource[]) => void,
    updateIdentity: (identity: number, name: string, roles: RoleResource[]) => void,
}

const CreateEditAdmin = ({ isOpen, roles, identity, closeDialog, createIdentity, updateIdentity }: CreateEditAdminProps) => {
    const intl = useIntl()

    const [name, setName] = React.useState<string | undefined>(undefined)
    const [password, setPassword] = React.useState<string | undefined>(undefined)
    const [selectedRoles, setSelectedRoles] = React.useState<RoleResource[] | undefined>(undefined)
    const [saveEnabled, setSaveEnabled] = React.useState<boolean>(false)

    const handleClickClose = () => {
        closeDialog()
        setName(undefined)
    }

    const onSelectedItemsChanged= (items: readonly string[]) => {
        setSelectedRoles(roles?.filter(role => items.find(it => it === role.name)))
    }

    React.useEffect(() => {
        setName(identity?.name && isOpen ? identity?.name : undefined)
        setSelectedRoles(identity?.roles && isOpen ? identity?.roles : undefined)
        setPassword(undefined)
    }, [identity, isOpen])

    React.useEffect(() => {
        if(name && selectedRoles?.length != 0 && (identity || password)) {
            setSaveEnabled(true)
        } else {
            setSaveEnabled(false)
        }
    }, [name, password, selectedRoles])

    const handleClickSave = () => {
        if (name && selectedRoles && selectedRoles?.length != 0) {
            if(identity) {
                updateIdentity(identity.id!, name, selectedRoles)
            } else if(password)
            {
                createIdentity(name, password, selectedRoles)
            }
        }
    }
    return (
        <Dialog fullScreen open={isOpen} onClose={handleClickClose}>
            <Toolbar>
                <Typography variant="h6">
                    <FormattedMessage id='identity.dialog.title' />
                </Typography>

                <div style={{ marginLeft: 'auto' }}>
                    <IconButton color="inherit" onClick={handleClickClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </Toolbar>

            <ContentContainer backgroundColor='white' >
                
                <Typography variant='body1'>
                    <FormattedMessage id='identity.description'/>
                </Typography>
                
                <form noValidate={true} autoComplete="off">
                    <TextField
                        required
                        error={false}
                        fullWidth={true}
                        disabled={identity ? true : false}
                        id="name"
                        type="text"
                        value={name || ''}
                        label={intl.formatMessage({ id: "identity.name" })}
                        placeholder={intl.formatMessage({ id: "identity.name.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setName(event.target.value) }}
                    />

                    <TextField
                        required
                        disabled={identity ? true : false}
                        error={false}
                        fullWidth={true}
                        id="password"
                        type="password"
                        value={password || ''}
                        label={intl.formatMessage({ id: "identity.password" })}
                        placeholder={intl.formatMessage({ id: "identity.password.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setPassword(event.target.value) }}
                        helperText={intl.formatMessage({ id: 'register.password.requirements-helperText' })}
                    />

                    {
                        roles ? <TransferList items={roles?.map(role =>role.name!)} value={identity?.roles?.map(role => role.name!) || []} onSelectedItemsChanged={onSelectedItemsChanged} /> : null
                    }

                </form>

                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={handleClickSave}
                    disabled={!saveEnabled}>
                    <FormattedMessage id="identity.save.button" />
                </Button>
            </ContentContainer>

        </Dialog>
    );
}


export default CreateEditAdmin;
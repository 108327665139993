import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { SketchPicker, ColorResult, Color } from 'react-color'
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import HelpIcon from '@mui/icons-material/Help';

type ColorPickerProps = {
    color: Color | undefined,
    onChange: (color: ColorResult) => void,
    id: string,
    toolTipId: string,
}

const ColorPicker = ({ id, toolTipId, color, onChange }: ColorPickerProps) => {

    const [isPickerOpen, setIsPickerOpen] = React.useState<boolean>(false)
    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false)

    const intl = useIntl()

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>

                {toolTipId ? <Tooltip title={intl.formatMessage({ id: toolTipId })}>
                    <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                        <HelpIcon />
                    </IconButton>
                </Tooltip> : null}

                <Typography variant='body1'>
                    <FormattedMessage id={id} />
                </Typography>


                <Typography variant='body1' margin={'0.2rem'}>
                    {color?.toString()}
                </Typography>


                <IconButton style={{ border: 'black', borderRadius: 0, borderStyle: 'solid', borderWidth: 'thin', padding: '1px', margin: '0.2rem' }} onClick={() => setIsPickerOpen(true)}>
                    <div style={{ background: color?.toString(), height: '1.5rem', width: '1.5rem' }} />
                </IconButton>

                {isPickerOpen ?
                    <div style={{
                        position: 'absolute',
                        zIndex: '2'
                    }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => setIsPickerOpen(false)} />
                        <SketchPicker color={color} onChange={(color) => onChange(color)} /> </div> : null}

            </div>

            {isTooltipOpen ? <Typography variant='body1'>
                <FormattedMessage id={intl.formatMessage({ id: toolTipId })} />
            </Typography> : null}
        </div>

    )
}

export default ColorPicker;
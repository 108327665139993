import React, { Suspense, VoidFunctionComponent } from 'react';
import ReactLoading from "react-loading"
import { FormattedMessage } from 'react-intl'
import { Grid } from "@mui/material"
import { ProjectResource, PrivilegeResource, RoleResource, ReleaseResource, IdentityResource, UserResource, InviteResource, EnvironmentResource, ServiceResourceTypeEnum, CompanyResource, ServicePropertyTypeResource, MessageResource, ConsensusResource } from '../generated/launchpad-api';
import { Environment } from '../Environment';
import { FooterResource, LanguageResource, MailResource } from '../generated/mail-api';

export type LaunchpadComponentProps = {
  isAuthenticated: boolean,
  environment: Environment | undefined,
  footers: (FooterResource | undefined)[] | undefined,
  languages: LanguageResource[] | undefined,
  identity: IdentityResource | undefined,
  user: UserResource | undefined,
  company: CompanyResource | undefined,
  companyProjects: ProjectResource[] | undefined,
  projects: ProjectResource[] | undefined,
  allRoles: RoleResource[] | undefined,
  servicePropertyTypes: ServicePropertyTypeResource[] | undefined,
  messages: MessageResource[] | undefined,
  recipes: string[] | undefined,
  createProject: (name: string, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => void,
  updateProject: (id: number, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => void,
  createRole: (name: string, project: number, isPublic: boolean, privileges: PrivilegeResource[], description: string) => void,
  updateRole: (role: number, name: string, isPublic: boolean, privileges: PrivilegeResource[], description: string) => void,
  createIdentity: (name: string, password: string, roles: RoleResource[]) => void,
  updateIdentity: (identity: number, name: string, roles: RoleResource[]) => void,
  createPrivileges: (name: string[], project: number) => Promise<void>,
  createService: (project: number, name: string, description: string, dependencies: number[] | undefined, otherDependencies: number[] | undefined, type: ServiceResourceTypeEnum) => void,
  deleteService: (serviceId: number) => void,
  updateService: (service: number, name: string, description: string, dependencies: number[] | undefined, otherDependencies: number[] | undefined, type: ServiceResourceTypeEnum) => void,
  createRelease: (targetEnvironment: number, major: number, minor: number, patch: number, cause: string, recipes: string[], success: (result: ReleaseResource) => void, error: (result: string) => void) => void,
  promoteRelease: (releaseId: number, environmentId: number, success: (result: ReleaseResource) => void, error: (result: string) => void) => void,
  createEnvironmentVariable: (service: number, key: string, value: string | undefined, secretId: number | undefined, parameterId: number | undefined, isExpression: boolean) => void,
  updateEnvironmentVariable: (env: number, key: string, value: string | undefined, secretId: number | undefined, parameterId: number | undefined, isExpression: boolean) => void,
  deleteEnvironmentVariable: (env: number) => void,
  createParameter: (service: number, key: string, value: string) => void,
  updateParameter: (parameterId: number, key: string, value: string) => void,
  deleteParameter: (parameterId: number) => void,
  createSecret: (service: number, key: string) => void,
  updateSecret: (secretId: number, key: string) => void,
  deleteSecret: (secretId: number) => void,
  createVolume: (service: number, name: string, mount: string, size: string) => void,
  updateVolume: (volumeId: number, name: string, mount: string, size: string) => void,
  deleteVolume: (volumeId: number) => void,
  uploadFavIcon: (id: number, file: File) => void,
  uploadAppleTouchIcon: (id: number, file: File) => void,
  uploadLogo: (id: number, file: File) => void,
  uploadIcon: (id: number, file: File) => void,
  uploadMaskableIcon: (id: number, file: File) => void,
  createMail?: (name: string, description: string, footer: string | undefined, callback?: (mail: MailResource) => void) => void,
  updateMail: (id: number, name: string, description: string, footer: string | undefined, callback?: (mail: MailResource) => void) => void,
  createFooter: (name: string, description: string, callback?: (footer: FooterResource) => void) => void,
  updateFooter: (id: number, name: string, description: string, callback?: (footer: FooterResource) => void) => void,
  createMailTemplate?: (mailId: number, subject: string, body: string, language: string, callback?: (mail: MailResource) => void) => void,
  updateMailTemplate?: (id: number, subject: string, body: string, language: string, callback?: (mail: MailResource) => void) => void,
  createFooterTemplate: (footerId: number, language: string, body: string, callback?: (footer: FooterResource) => void) => void,
  updateFooterTemplate: (id: number, language: string, body: string, callback?: (footer: FooterResource) => void) => void,
  deleteFooterTemplate: (id: number, callback?: (footer: FooterResource) => void) => void,
  deleteMailTemplate: (id: number, callback?: (footer: MailResource) => void) => void,
  deleteMail: (id: number, callback?: () => void) => void,
  deleteFooter: (id: number, callback?: () => void) => void,
  downloadRecipe: (project: number, recipes: string[], success: () => void, error: (result: string) => void) => void,
  verifyRecipe: (project: number, recipes: string[], success: (result: string) => void, error: (result: string) => void) => void,
  createInvite: (projectId: number, email: string, success: (result: InviteResource) => void, error: (result: string) => void) => void,
  createCompanyInvite: (companyId: number, email: string, success: (result: InviteResource) => void, error: (result: string) => void) => void,
  createEnvironment: (projectId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,
  updateEnvironment: (environmentId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,
  deleteEnvironment: (environmentId: number, successCallback: () => void, errorCallback: () => void) => void,
  updateProjectGitConfiguration: (id: number, repository: string, username: string, password: string, successCallback: () => void, errorCallback: () => void) => void,
  createTags: (names: string[], project: number) => void,
  answerMessage: (id: number, message: string) => void,
  updateCompanyGitConfiguration: (id: number, repository: string, username: string, password: string, successCallback: () => void, errorCallback: () => void) => void,
  updateProjectRecipes: (project: number, recipes: string[], successCallback: () => void, errorCallback: () => void) => void,
  getConsensus: (releaseId: number, success: (consensus: ConsensusResource) => void, error: () => void) => void,
}

type ComponentProps = {
  component: React.LazyExoticComponent<(props: LaunchpadComponentProps) => JSX.Element>
}

type RootProps = ComponentProps & LaunchpadComponentProps


const Root = (props: RootProps) => {

  const TestComponent = props.component

  return (
      <Suspense fallback={
        <Grid container textAlign={'center'} alignSelf={'center'} alignItems={'center'} alignContent={'center'} justifySelf={'center'} justifyItems={'center'} justifyContent={'center'}>
          <Grid item>
            <ReactLoading type={'bars'} color={'#BC3143'} height={60} width={60} />
            <FormattedMessage id="root.loading" />
          </Grid>
        </Grid>}>
      <TestComponent {...props}/>
      </Suspense>
  );
}

export default Root

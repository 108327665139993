import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/App/App';
import reportWebVitals from './reportWebVitals';
import { Environment } from './Environment';
import theme from './theme'
import { BrowserRouter } from "react-router-dom";
import locale_de from "./translations/de.json";
import locale_en from "./translations/en.json";
import locale_fr from "./translations/fr.json";

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { render } from "react-dom"; 

const languages = new Map([
  ["de", locale_de],
  ["en", locale_en],
  ["fr", locale_fr]
]);

function getLanguage(language: string): string {
  return languages.has(language) ? language : languages.has(language.split(/[-_]/)[0]) ? language.split(/[-_]/)[0] : 'en'
}

const language = getLanguage(navigator.language)

// initialize environment singleton
Environment.getInstance()

render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={languages.get(language)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
            <App language={language} />
        </BrowserRouter>
        </LocalizationProvider>
      </IntlProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { IconButton, Dialog, Toolbar, Button, TextField, Box, Stack, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close';
import { EnvironmentResource, ProjectResource, TagResource, UserResource } from '../generated/launchpad-api';
import ContentContainer from '../ContentContainer/ContentContainer';
import EnvironmentsDiagram from './EnvironmentsDiagram';
import { ReactFlowProvider } from 'reactflow';
import { DirectedGraph } from 'typescript-graph';
import { createFilterOptions } from '@mui/material/Autocomplete';

type CreateEditEnvironmentProps = {
    isOpen: boolean,
    selectedProject: ProjectResource | undefined,
    selectedEnvironment: EnvironmentResource | undefined,
    user: UserResource | undefined,
    closeDialog: () => void,
    createEnvironment: (projectId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,
    updateEnvironment: (environmentId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,
    createTags: (names: string[], project: number) => void,
}

const CreateEditEnvironment = ({ isOpen, selectedProject, selectedEnvironment, user, closeDialog, createEnvironment, updateEnvironment, createTags }: CreateEditEnvironmentProps) => {
    const intl = useIntl()

    const [saveEnabled, setSaveEnabled] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string | undefined>(selectedEnvironment?.name)
    const [precedentEnvironment, setPrecedentEnvironment] = React.useState<EnvironmentResource | undefined>(selectedEnvironment?.precedentEnvironment)
    const [domain, setDomain] = React.useState<string | undefined>(undefined)

    const [tags, setTags] = React.useState<string[] | undefined>(undefined)

    const diagram = React.useRef<HTMLDivElement | undefined>(undefined)

    const stringChanged = (src: string | undefined, origin: string | undefined) => {
        return src && src.trim().length !== 0 && src !== origin
    }

    React.useEffect(() => {

        if (name
            && domain
            && (stringChanged(name, selectedEnvironment?.name)
                || precedentEnvironment !== selectedEnvironment?.precedentEnvironment
                || stringChanged(domain, selectedEnvironment?.domain)
            || !(tags?.map(t => selectedEnvironment?.tags?.map(g => g.name!).includes(t) ?? false).reduce(function (a, b) { return a && b }, selectedEnvironment?.tags?.length === tags.length))  )
            && (selectedProject || selectedEnvironment)) {
            setSaveEnabled(true)
        } else {
            setSaveEnabled(false)
        }

    }, [name, selectedProject, selectedEnvironment, precedentEnvironment, domain, tags])

    React.useEffect(() => {
        if (!isOpen) {
            setName(undefined)
            setPrecedentEnvironment(undefined)
            setDomain(undefined)
            setName(undefined)
            setTags(undefined)
        } else {
            setName(selectedEnvironment?.name)
            setPrecedentEnvironment(selectedEnvironment?.precedentEnvironment)
            setDomain(selectedEnvironment?.domain)
            setName(selectedEnvironment?.name)
            setTags(selectedEnvironment?.tags?.map(tag => tag.name!))
        }
    }, [isOpen, selectedEnvironment])

    const handleClickClose = () => {
        closeDialog()
    }

    const handleClickSave = () => {
        if (name && domain) {
            if (selectedEnvironment) {
                updateEnvironment(selectedEnvironment.id!, name, precedentEnvironment?.id, domain, selectedProject?.tags?.filter(tag => tags?.includes(tag.name!)).map(tag => tag.id!) ?? [],  () => {
                    closeDialog()
                }, () => {

                })
            } else if (selectedProject) {
                createEnvironment(selectedProject.id!, name, precedentEnvironment?.id, domain, selectedProject?.tags?.filter(tag => tags?.includes(tag.name!)).map(tag => tag.id!) ?? [], () => {
                    closeDialog()
                }, () => {

                })
            }
        }
    }

    const canAddEnvironment = (environments: EnvironmentResource[], selectedEnvironment: EnvironmentResource) => {
        var source = environments?.filter(env => env.id !== selectedEnvironment.id).concat([selectedEnvironment])

        if (source.length > 1) {
            type NodeType = { id: Number, name: string }
            const directedGraph = new DirectedGraph<NodeType>((n: NodeType) => n.id.toString())

            // add nodes
            source?.forEach(environment => {
                directedGraph.insert({ id: environment.id!, name: environment.id!.toString() })
            })

            // add edges
            source.filter(environment => environment.precedentEnvironment ?? false)?.forEach(environment => {
                var src = directedGraph.getNodes().find(node => node.id === environment.precedentEnvironment!.id)
                var dest = directedGraph.getNodes().find(node => node.id === environment.id)

                directedGraph.addEdge(src!.id.toString(), dest!.id.toString())
            })

            // evaluate
            return directedGraph.isAcyclic()

        } else {
            return false
        }
    }

    const filter = createFilterOptions<TagResource>();

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClickClose}>
            <Toolbar>
                <Stack direction="row" spacing={2} style={{ marginLeft: 'auto' }}>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={handleClickSave}
                        disabled={user?.subscription?.subscriptionDisabled || !saveEnabled}>
                        <FormattedMessage id="create.secret.save.button" />
                    </Button>

                    <IconButton color="inherit" onClick={handleClickClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Toolbar>

            <ContentContainer backgroundColor='white' >


                <form noValidate={true} autoComplete="off">
                    <TextField
                        required
                        error={false}
                        fullWidth={true}
                        id="name"
                        type="text"
                        value={name || ''}
                        label={intl.formatMessage({ id: "create.project.environment.name" })}
                        placeholder={intl.formatMessage({ id: "create.project.environment.name.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setName(event.target.value) }}
                    />

                    <TextField
                        error={false}
                        fullWidth={true}
                        id="domain"
                        type="text"
                        value={domain || ''}
                        label={intl.formatMessage({ id: "project.domain" })}
                        placeholder={intl.formatMessage({ id: "project.domain.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setDomain(event.target.value) }}
                    />


                    <FormControl style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                        <InputLabel>
                            <FormattedMessage id="create.project.environment.precedent" />
                        </InputLabel>
                        <Select
                            value={precedentEnvironment?.id || ''}
                            onChange={(event) => { setPrecedentEnvironment(selectedProject?.environments?.find(environment => environment.id === event.target.value)) }}>
                            <MenuItem key={"null"} value={"null"}>
                                {intl.formatMessage({ id: "create.project.environment.precedent.placeholder" })}
                            </MenuItem>

                            {selectedProject?.environments?.filter(environment => environment.id !== selectedEnvironment?.id && canAddEnvironment(selectedProject.environments!, { 'id': selectedEnvironment?.id ?? -1, 'name': name ?? 'undefined', 'precedentEnvironment': environment }) ).map((value) => (
                                <MenuItem key={value.id} value={value.id}>
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        value={selectedProject?.tags?.filter(tag => tags?.find(test => test === tag.name)) || []}
                        options={selectedProject?.tags ?? []}
                        getOptionDisabled={(option) =>  selectedProject?.environments?.filter(env => env.id !== selectedEnvironment?.id ).map(env => env.tags?.map(t => t.name))?.flat().includes(option.name) ?? false} // disable all options, just allow creation of a new value
                        getOptionLabel={(option) => option.name!}
                        filterSelectedOptions
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    id: -1,
                                    name: inputValue,
                                });
                            }

                            return filtered;
                        }}
                        onChange={(event: any, newValue: TagResource[]) => {

                            var newOptions = newValue.filter(privilege => privilege.id === -1).map(item => item.name!)
                            if (newOptions.length !== 0) {
                                createTags(newOptions, selectedProject?.id!)
                            }

                            setTags(newValue.map(item => item.name!))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="filterSelectedOptions"
                                placeholder="Tags"
                            />
                        )}
                    />

                </form>

                <ReactFlowProvider>
                    <Box ref={diagram} style={{ background: 'white' }} >
                        <EnvironmentsDiagram environments={selectedProject?.environments} selectedEnvironment={{ 'id': selectedEnvironment?.id, 'name': name, 'precedentEnvironment': precedentEnvironment }} fullscreen={() => diagram.current?.requestFullscreen()} windowed={() => document.exitFullscreen()} />
                    </Box>
                </ReactFlowProvider>

            </ContentContainer>
        </Dialog>
    )
}

export default CreateEditEnvironment;
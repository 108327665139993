import React, { useEffect } from 'react';

import { Stepper, Step, StepLabel, Card, CardActions, CardContent, CardHeader, TextField, Button, MenuItem, Box, FormControl, InputLabel, Select, Input, Chip, Typography } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl'
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import ContentContainer from '../ContentContainer/ContentContainer'
import { UserResource, UserResourceTitleEnum, UserResourceStateEnum, IdentityResource, CompanyResource } from '../generated/launchpad-api/api'
import 'react-phone-input-2/lib/style.css'

enum STEPS {
  COMPANY,
  USER,
  ACTIVATION
}

const stepsKeys = Object.keys(STEPS).filter(k => typeof STEPS[k as any] === "number")

const titles = Object.values(UserResourceTitleEnum)


type UserOnboardingProps = {
  user: UserResource | undefined,
  activateUser: (companyName: string, companyPhone: number | undefined, firstname: string, lastname: string, title: UserResourceTitleEnum) => void,
  activateCompanyUser: (firstname: string, lastname: string, title: UserResourceTitleEnum) => void,
  company: CompanyResource | undefined
}

const UserOnboarding = ({ user, company, activateCompanyUser, activateUser }: UserOnboardingProps) => {

  const intl = useIntl()
  const navigate = useNavigate()

  const [step, setStep] = React.useState<STEPS>(STEPS.COMPANY)

  const [companyName, setCompanyName] = React.useState<string | undefined>(undefined)
  const [phone, setPhone] = React.useState<string | undefined>(undefined)
  const [isCompanyButtonDisabled, setIsCompanyButtonDisabled] = React.useState<boolean>(true)

  const [firstname, setFirstname] = React.useState<string | undefined>(undefined)
  const [lastname, setLastname] = React.useState<string | undefined>(undefined)
  const [title, setTitle] = React.useState<UserResourceTitleEnum | undefined>(undefined)
  const [isIdentityButtonDisabled, setIsIdentityButtonDisabled] = React.useState<boolean>(true)

  useEffect(() => {
    if (user?.state !== UserResourceStateEnum.Created) {
      navigate("/")
    }
  }, [user]);

  useEffect(() => {
    if (company) {
      setCompanyName(company.name)
      setPhone(company.phone ? company.phone?.toString() : undefined)
    }
  }, [company]);
 
  useEffect(() => {
    switch (step) {
      case STEPS.COMPANY: {
        if (company || (companyName !== undefined && companyName.length !== 0)) {
          setIsCompanyButtonDisabled(false)

        } else {
          setIsCompanyButtonDisabled(true)
        }
        break
      }
      case STEPS.USER: {
        if (firstname !== undefined && firstname.length !== 0 && lastname !== undefined && lastname.length !== 0 && title !== undefined) {
          setIsIdentityButtonDisabled(false)

        } else {
          setIsIdentityButtonDisabled(true)
        }
        break
      }
      default: {
        break
      }
    }
  }, [step, companyName, firstname, lastname, title]);

  const handleBack = () => {
    switch (step) {
      case STEPS.USER: {
        setStep(STEPS.COMPANY)
        break
      }
      case STEPS.ACTIVATION: {
        setStep(STEPS.USER)
        break
      }
      default: {
        console.error('unhandled previous step')
      }
    }
  }

  const handleNext = () => {
    switch (step) {
      case STEPS.COMPANY: {
          setStep(STEPS.USER)
        break
      }
      case STEPS.USER: {
        setStep(STEPS.ACTIVATION)
        break
      }
      case STEPS.ACTIVATION: {
        if (firstname && lastname && title) {
          if (company) {
            activateCompanyUser(firstname, lastname, title)
          } else {
            if (companyName) {
              activateUser(companyName, phone ? Number(phone) : undefined, firstname, lastname, title)
            }
          }
        }

        break
      }
      default: {
        console.error('unhandled next step')
      }
    }
  };
 
  const handlePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ): void => {
    if (typeof e === "string") {
      setPhone(e)
    }
  }

  return (
    <ContentContainer backgroundColor='white'>

      <Typography variant="h6" textAlign={"center"}>
        <FormattedMessage id="onboarding.title"/>
      </Typography>
      <Typography variant="body1" textAlign={"center"}>
        <FormattedMessage id="onboarding.subtitle"/>
      </Typography>

      <Stepper activeStep={step} alternativeLabel style={{ justifyContent: 'center', marginBottom: "1rem", marginTop: "1rem" }}>
        {stepsKeys.map((label) => (
          <Step key={label}>
            <StepLabel>{intl.formatMessage({ id: "ONBOARDING_" + label })}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form noValidate={true} autoComplete="off" style={{ display: step == STEPS.COMPANY ? 'block' : 'none' }}>
        <Card elevation={3}>
          <CardHeader title={intl.formatMessage({ id: "onboarding.company" })} />
          <CardContent>
            <Box display='flex' flexDirection='column'>

              <TextField
                required
                error={false}
                fullWidth={true}
                id="companyname"
                type="text"
                value={companyName || ''}
                label={intl.formatMessage({ id: "onboarding.company.name" })}
                placeholder={intl.formatMessage({ id: "onboarding.company.name.placeholder" })}
                margin="normal"
                onChange={(event) => setCompanyName(event.target.value)}
                disabled={company ? true : false}
              />

              <Typography style={{ marginTop: '1rem' }}>
                {intl.formatMessage({ id: "onboarding.company.phone" })}
              </Typography>

              <PhoneInput containerClass="classes.phone" onChange={handlePhoneChange} value={phone} disabled={company ? true : false}/>

            </Box>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="large"
              color="secondary"

              onClick={handleNext}
              disabled={isCompanyButtonDisabled}>
              <FormattedMessage id="general.navigation.next.button"/>
            </Button>
          </CardActions>
        </Card>
      </form>

      <form noValidate={true} autoComplete="off" style={{ display: step == STEPS.USER ? 'block' : 'none' }}>
        <Card elevation={3}>
          <CardHeader title={intl.formatMessage({ id: "onboarding.user" })} />
          <CardContent>
            <Box display='flex' flexDirection='column'>

              <Box display='flex' flexDirection='row'>
                <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
                  <FormattedMessage id="onboarding.user.identity.email"/>
                </Typography>
                <Typography variant="h6">{user?.identityResource?.name}</Typography>
              </Box>

              <FormControl margin="normal" required>
                <InputLabel id="title-label">
                  <FormattedMessage id="onboarding.user.title"/>
                </InputLabel>
                <Select
                  MenuProps={{
                    style: {
                      maxHeight: "50%",
                    },
                  }}
                  fullWidth
                  labelId="status-select-title-label"
                  id="status-select-title"
                  value={title || ""}
                  onChange={(event) => {
                    setTitle(event.target.value as UserResourceTitleEnum)
                  }}>
                  {titles.map((value) => (
                    <MenuItem key={value} value={value}>
                      {intl.formatMessage({ id: "USER_TITLE_" + value })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                required
                margin="normal"
                error={false}
                fullWidth={true}
                id="firstname"
                type="text"
                value={firstname || ""}
                label={intl.formatMessage({ id: "onboarding.user.firstname" })}
                placeholder={intl.formatMessage({ id: "onboarding.user.firstname.placeholder" })}
                onChange={(evemt) => setFirstname(evemt.target.value)}
              />

              <TextField
                required
                margin="normal"
                error={false}
                fullWidth={true}
                id="lastName"
                type="text"
                value={lastname || ""}
                label={intl.formatMessage({ id: "onboarding.user.lastname" })}
                placeholder={intl.formatMessage({ id: "onboarding.user.lastname.placeholder" })}
                onChange={(event) => setLastname(event.target.value)}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="large"
              color="secondary"

              onClick={handleBack}>
              <FormattedMessage id="general.navigation.back.button"/>
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleNext}
              disabled={isIdentityButtonDisabled}
            >
              <FormattedMessage id="general.navigation.next.button"/>
            </Button>
          </CardActions>
        </Card>
      </form>

      <form noValidate={true} autoComplete="off" style={{ display: step == STEPS.ACTIVATION ? 'block' : 'none' }}>
        <Card elevation={3}>
          <CardHeader title={intl.formatMessage({ id: "onboarding.activation" })} />
          <CardContent>
            <Typography variant="body1" textAlign={"left"}>
              <FormattedMessage id="onboarding.activation.description" />
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="large"
              color="secondary"

              onClick={handleBack}>
              <FormattedMessage id="general.navigation.back.button"/>
            </Button>

            <Button
              variant="contained"
              size="large"
              color="secondary"

              onClick={handleNext}>
              <FormattedMessage id="general.navigation.next.button"/>
            </Button>
          </CardActions>
        </Card>
      </form>

    </ContentContainer>
  );
};

export default UserOnboarding;
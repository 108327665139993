import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import HelpIcon from '@mui/icons-material/Help';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { ProjectResource } from '../generated/launchpad-api';
import styled from '@emotion/styled'

type LogoSelectorProps = {
    id: string,
    toolTipId: string,
    uploadIcon: (id: number, file: File) => void,
    project: ProjectResource | undefined,
    path: string | undefined,
    accept: string
    minWidth?: string | number
}

const LogoSelector = ({ id, toolTipId, project, path, accept, minWidth, uploadIcon }: LogoSelectorProps) => {

    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false)

    const intl = useIntl()

    const handleUpload: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.files == null || project == undefined) {
            return
        }

        uploadIcon(project.id!, event.target.files[0])
    }

    let dark = '#777';
    let light = '#ccc';

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>

                {toolTipId ? <Tooltip title={intl.formatMessage({ id: toolTipId })}>
                    <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                        <HelpIcon />
                    </IconButton>
                </Tooltip> : null}

                <Typography variant='body1'>
                    <FormattedMessage id={id} />
                </Typography>


                <IconButton component="label">
                    <CloudUpload />
                    <input accept={accept} hidden type="file" onChange={handleUpload} />
                </IconButton>

            </div>

            {isTooltipOpen ? <Typography variant='body1'>
                <FormattedMessage id={intl.formatMessage({ id: toolTipId })} />
            </Typography> : null}


            {
                path ?
                <div>
                        <div style={{
                            minWidth: minWidth ?? 'auto',
                            display: 'inline-flex',
                            backgroundImage: "linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)",
                            backgroundSize: '20px 20px',
                            backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
                        }}>
                            <img src={path}></img>
                        </div>
                </div>


                    : null
            }
        </div>
    )
}

export default LogoSelector;
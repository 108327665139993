import React from 'react';
import { IconButton, Stack, Divider } from '@mui/material';
import ReactFlow, { useNodesState, useEdgesState, useReactFlow, useOnSelectionChange, MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { EnvironmentResource } from '../generated/launchpad-api';
import dagre from 'dagre';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

type EnvironmentsDiagramProps = {
    environments: EnvironmentResource[] | undefined,
    selectedEnvironment?: EnvironmentResource | undefined,
    onSelected?: (service: Number | undefined) => void,
    fullscreen: () => void,
    windowed: () => void,
};

const getEnvironmentId = (id: Number | undefined) => {
    return id?.toString() ?? "undefined"
}

export const getNodes = (environments: EnvironmentResource[] | undefined, selectedEnvironment: EnvironmentResource | undefined) => {
    const newEnvironmentArray = selectedEnvironment?.name ? [{ id: getEnvironmentId(selectedEnvironment.id), position: { x: 0, y: 0 }, data: { label: selectedEnvironment?.name }}] : []
    const environmentNodes = environments ? environments.filter(environment => environment.id !== selectedEnvironment?.id).map(environment => { return { id: getEnvironmentId(environment.id), position: { x: 0, y: 0 }, data: { label: environment.name! } } }) : []

    var result = newEnvironmentArray.concat(environmentNodes)

    // distinct
    const distinct = []
    const map = new Map()
    for (const item of result) {
        if (!map.has(item.id)) {
            map.set(item.id, true)
            distinct.push(item)
        }
    }
    
    return distinct.sort((a, b) => a.id > b.id ? 1 : -1)
}

export const getEdges = (environments: EnvironmentResource[] | undefined, selectedEnvironment: EnvironmentResource | undefined) => {
    var environmentNodes = environments ? environments.filter(environment => environment.id !== selectedEnvironment?.id) : []

    if (selectedEnvironment) {
        environmentNodes = environmentNodes.concat([selectedEnvironment])
    }

    var edges = environmentNodes.filter(environment => environment.precedentEnvironment ?? false)

    return edges.map(edge => {
        return {
            id: "e-" + getEnvironmentId(edge.precedentEnvironment!.id) + "-" + getEnvironmentId(edge.id), source: getEnvironmentId(edge.precedentEnvironment!.id), target: getEnvironmentId(edge.id), markerEnd: {
                type: MarkerType.Arrow,
                width: 20,
                height: 20,
            }, pathOptions: { offset: 20, borderRadius: 50 }
        }
    })
}

const EnvironmentsDiagram = ({ environments, selectedEnvironment, onSelected, fullscreen, windowed }: EnvironmentsDiagramProps) => {

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 300;
    const nodeHeight = 70;

    const getLayoutedElements = (nodes: any, edges: any) => {
        dagreGraph.setGraph({ rankdir: "LR" });

        nodes.forEach((node: any) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge: any) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node: any) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = 'left';
            node.sourcePosition = 'right';

            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };

            return node;
        });

        return { nodes, edges };
    };

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowInstance = useReactFlow();
    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false)
    const [height, setHeight] = React.useState('100vh')



    React.useEffect(() => {
        if(isFullscreen) {
            setHeight('100vh')
            fullscreen()
        } else {
            setHeight('40rem')
            windowed()
        }

        setTimeout(() => reactFlowInstance.fitView(), 50)

     }, [isFullscreen])

    useOnSelectionChange({
        onChange: ({ nodes, edges }) =>  {
            if(onSelected) {
                onSelected(nodes.length === 0 ? undefined : Number(nodes[0].id))
            }
        }
    });

    React.useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            getNodes(environments, selectedEnvironment),
            getEdges(environments, selectedEnvironment)
        );

        setNodes([...layoutedNodes].map((node) => {
            node.data = {
                ...node.data,
            };
            node.style = {
                borderWidth: '1px'
            }

            return node;
        }));


        setEdges([...layoutedEdges].map((edge) => {
            edge.data = {
                ...edge.data,
            };
            edge.style = {
            }

            return edge;
        }));

        setTimeout(() => reactFlowInstance.fitView(), 50)

    }, [environments, selectedEnvironment])

    const handleFitView = () => {
        reactFlowInstance.fitView()
    }

    React.useEffect(() => {
        setNodes((nds) =>
            nds.map((node) => {
                node.data = {
                    ...node.data,
                };
                node.style = {
                    borderWidth: '1px'
                } 

                return node;
            })
        );
    }, [setNodes]);

    React.useEffect(() => {
        setEdges((edges) =>
            edges.map((edge) => {
                edge.data = {
                    ...edge.data,
                };
                edge.style = {
                }

                return edge;
            })
        );
    }, [setEdges]);

    return (
        <div style={{ height: height, width: "100%", display: 'flex', border: '1px solid lightgray'}}>

            <Stack direction="column" spacing={2} style={{ marginLeft: 'auto', width: 'fit-content', margin: '1rem', height: 'inherit' }}>
                <IconButton color="inherit" onClick={handleFitView} aria-label="fit view">
                    <FitScreenIcon />
                </IconButton>

                <IconButton color="inherit" onClick={() => setIsFullscreen(true) } aria-label="full screen" style={{display: isFullscreen ? "none": "block"}}>
                    <FullscreenIcon/>
                </IconButton>

                <IconButton color="inherit" onClick={() => { setIsFullscreen(false) }} aria-label="windowed" style={{ display: isFullscreen ? "block" : "none" }}>
                    <FullscreenExitIcon />
                </IconButton>

            </Stack>

            <Divider style={{ borderColor: 'lightGrey', marginRight: '1rem' }} orientation="vertical" />

            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodesConnectable={false}
                nodesDraggable={true}
                >
            </ReactFlow>


        </div>
    );
}

export default EnvironmentsDiagram

import React, {PropsWithChildren} from 'react';
import styled from '@emotion/styled'

const ContentCanvas = styled.div({
    width: '66%',
    margin: 'auto',
    [`@media screen and (max-width: 1200px)`]: {
        width: '100%',
    },
})

type ContentContainerProps = {
    backgroundColor?: string,
};

class ContentContainer extends React.Component<PropsWithChildren<ContentContainerProps>> {
    render() {
        return (
            <div style={{ backgroundColor: this.props.backgroundColor, margin:'1rem' }}>
                <ContentCanvas>
                    {this.props.children}
                </ContentCanvas>
            </div>
        );
    }
}

export default ContentContainer

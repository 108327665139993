import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import Root from '../Root/Root';
import { useIntl, FormattedMessage } from 'react-intl';
import { Environment, RetryConfig, withCredentialsRetries } from '../Environment'
import { Route, Routes } from 'react-router-dom';
import theme from '../theme'
import { useNavigate } from 'react-router-dom'
import { ListItemIcon } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button'
import Project from '../CreateEditProject/CreateEditProject';
import { CompanyResource, ConsensusResource, EnvironmentResource, IdentityResource, InviteResource, MessageResource, ProjectResource, ServicePropertyTypeResource, ServiceResourceTypeEnum, UserResource, UserResourceStateEnum, UserResourceTitleEnum } from '../generated/launchpad-api';
import CreateEditAdmin from '../CreateEditAdmin/CreateEditAdmin';
import { RoleResource, PrivilegeResource, ReleaseResource } from '../generated/launchpad-api';
import axios from 'axios'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmailIcon from '@mui/icons-material/Email';
import { FooterResource, LanguageResource, MailResource } from '../generated/mail-api';
import UserOnboarding from '../UserOnboarding/UserOnboarding';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

type AppProps = {
  language: string,
}

const App = ({ language }: AppProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)
  const [isProjectDialogOpen, setIsProjectDialogOpen] = React.useState<boolean>(false)
  const [iscreateIdentityDialogOpen, setIscreateIdentityDialogOpen] = React.useState<boolean>(false)
  const [environment, setEnvironment] = React.useState<Environment | undefined>(undefined)
  const [footers, setFooters] = React.useState<(FooterResource | undefined)[] | undefined>(undefined)
  const [languages, setLanguages] = React.useState<(LanguageResource)[] | undefined>(undefined)
  const [identity, setIdentity] = React.useState<IdentityResource | undefined>(undefined)

  const [user, setUser] = React.useState<UserResource | undefined>(undefined)
  const [projects, setProjects] = React.useState<ProjectResource[] | undefined>(undefined)
  const [company, setCompany] = React.useState<CompanyResource | undefined>(undefined)
  const [companyProjects, setCompanyProjects] = React.useState<ProjectResource[] | undefined>(undefined)

  const [allRoles, setAllRoles] = React.useState<RoleResource[] | undefined>(undefined)

  const [servicePropertyTypes, setServicePropertyTypes] = React.useState<ServicePropertyTypeResource[] | undefined>(undefined)

  const [messages, setMessages] = React.useState<MessageResource[] | undefined>(undefined)

  const [recipes, setRecipes] = React.useState<string[] | undefined>(undefined)

  const intl = useIntl()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (environment && company) {
      environment.launchpadAdminControllerApi.getCompanyRecipes(company.id!, withCredentialsRetries).then(result => {
        setRecipes(result.data)
      })
    }
  }, [environment, company]);


  React.useEffect(() => {
    Environment.getInstance().then((environment) => {
      setEnvironment(environment)
    })
  }, []);

  React.useEffect(() => {
    if (environment) {
      environment.launchpadPublicControllerApi.authenticated(withCredentialsRetries).then((result) => {
        setIsAuthenticated(result.data)
      })

      environment.mailAdminControllerApi.getFooterPaginated(undefined, undefined, undefined, withCredentialsRetries).then((result) => {
        var nullFooter: (FooterResource | undefined)[] = [undefined]
        setFooters(nullFooter.concat(result.data.content))
      })

      environment.mailAdminControllerApi.getLanguages(withCredentialsRetries).then((result) => {
        setLanguages(result.data)
      })

      environment.launchpadUserControllerApi.identity(withCredentialsRetries).then(result => {
        setIdentity(result.data)
      })

      environment.launchpadAdminControllerApi.user(withCredentialsRetries).then(result => {
        setUser(result.data)
      }).catch(() => {
        environment.launchpadAdminControllerApi.postUser(withCredentialsRetries).then(result => {
          setUser(result.data)
        })
      })

      environment.launchpadPublicControllerApi.getServicePropertyTypes().then(result => {
        setServicePropertyTypes(result.data)
      })
    }
  }, [environment]);

  React.useEffect(() => {
    if (environment && identity && identity.superUser) {
      environment.launchpadSUControllerApi.getRoles1(withCredentialsRetries).then((result) => {
        setAllRoles(result.data)
      })
    }
  }, [environment, identity]);


  React.useEffect(() => {
    if (environment && user) {
      environment.launchpadAdminControllerApi.getUserCompany(withCredentialsRetries).then(result => {
        setCompany(result.data)
      })

      switch (user.state) {
        case UserResourceStateEnum.Created: {
          navigate("/onboarding")
          break
        }
        case UserResourceStateEnum.Activated: {
          environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then(result => {
            setProjects(result.data)
            navigate("/projects")
          })

          environment.launchpadAdminControllerApi.getMessages(withCredentialsRetries).then(result => {
            setMessages(result.data)
          })

          break
        }
        default: {
          // nothing to do
        }
      }

    }
  }, [environment, user]);

  const compare = (a1: number[], a2: number[]) =>
    a1.length == a2.length &&
    a1.every(
      (element, index) => element === a2[index]
    );

  const updateState = React.useCallback(async () => {
    if (environment && user && user.state === UserResourceStateEnum.Activated) {
      environment.launchpadAdminControllerApi.getMessages(withCredentialsRetries).then(result => {
        if (messages) {
          var a = result.data.map(message => message.id!).sort()
          var b = messages.map(message => message.id!).sort()
          if (!compare(a, b)) {
            setMessages(result.data)
          }
        } else {
          setMessages(result.data)
        }
      })
    }
  }, [environment, user]);

  React.useEffect(() => {
    setInterval(updateState, 60000);
  }, [updateState, environment, user]);


  React.useEffect(() => {
    if (environment && projects) {
      environment.launchpadAdminControllerApi.getCompanyProjects(withCredentialsRetries).then(result => {
        setCompanyProjects(result.data)
      })
    }
  }, [environment, projects]);


  React.useEffect(() => {
    if (projects) {
      if (window.parent && (window.parent !== window)) {
        window.parent.postMessage({ type: "projects", content: projects?.filter(project => project.adminUrl && project.adminPrivilege).map(project => { return [project.name, project.adminUrl, project.adminPrivilege?.name] }) }, document.referrer);

      }
    } else {
      window.parent.postMessage({ type: "projects", content: undefined });
    }
  }, [projects])

  const links = [
    {
      name: intl.formatMessage({ id: 'projects.title' }),
      icon: <RocketLaunchIcon />,
      component: React.lazy(() => import('../Projects/Projects')),
      path: '/projects',
      display: user ? user.state !== UserResourceStateEnum.Created : false,
      actions: [{
        name: 'create.project',
        disabled: user?.subscription?.subscriptionDisabled ?? true,
        action: () => {
          setIsProjectDialogOpen(true)
        }
      }]
    },
    {
      name: intl.formatMessage({ id: 'users.title' }),
      icon: <PeopleIcon />,
      component: React.lazy(() => import('../Identities/Identities')),
      path: '/identities',
      display: (user?.state !== UserResourceStateEnum.Created ?? false) && (identity?.superUser ?? false),
      actions: [{
        name: 'create.identity',
        disabled: !(identity?.superUser),
        action: () => {
          setIscreateIdentityDialogOpen(true)
        }
      }]
    },
    {
      name: intl.formatMessage({ id: 'service.map.title' }),
      icon: <AccountTreeIcon />,
      component: React.lazy(() => import('../ServiceMap/ServiceMap')),
      path: '/servicemap',
      display: user?.state !== UserResourceStateEnum.Created ?? false,
      actions: []
    },
    {
      name: intl.formatMessage({ id: 'mails.title' }),
      icon: <EmailIcon />,
      component: React.lazy(() => import('../Mails/Mails')),
      path: '/mails',
      display: (user?.state !== UserResourceStateEnum.Created ?? false) && (identity?.superUser ?? false),
      actions: []
    },
    {
      name: intl.formatMessage({ id: 'settings.title' }),
      icon: <SettingsIcon />,
      component: React.lazy(() => import('../Settings/Settings')),
      path: '/settings',
      display: (user?.state !== UserResourceStateEnum.Created ?? false) && (identity?.superUser ?? false),
      actions: []
    },
    {
      name: intl.formatMessage({ id: 'company.title' }),
      icon: <SettingsIcon />,
      component: React.lazy(() => import('../Company/Company')),
      path: '/company',
      display: (user?.state !== UserResourceStateEnum.Created ?? false),
      actions: []
    }
  ];

  const list = () => (
    <Box
      role="presentation">
      <List>
        {links.filter(link => link.display).map(link =>
          <ListItem key={link.name} disablePadding>
            <ListItemButton onClick={() => navigate(link.path)}>
              <ListItemIcon>
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const activateCompanyUser = (firstname: string, lastname: string, title: UserResourceTitleEnum) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putActivateCompanyUser(firstname, lastname, title, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.user(withCredentialsRetries).then(result => {
          setUser(result.data)
        })
      })
    }
  }

  const activateUser = (companyName: string, companyPhone: number | undefined, firstname: string, lastname: string, title: UserResourceTitleEnum) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putActivateUser(companyName, firstname, lastname, title, companyPhone, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.user(withCredentialsRetries).then(result => {
          setUser(result.data)
        })
      })
    }
  }

  const createProject = (name: string, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createProject(name, description, adminUrl, shortname, themeColor, themeBackgroundColor, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then(result => {
          setProjects(result.data)
          setIsProjectDialogOpen(false);
        })
      })
    }
  }

  const updateProject = (id: number, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateProject(id, description, adminUrl, shortname, themeColor, themeBackgroundColor, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const createTags = (names: string[], project: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createTags(project, names, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateProjectGitConfiguration = (id: number, repository: string, username: string, password: string, successCallback: () => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putGit(id, username, repository, password, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
          successCallback()
        })
      }).catch(error => errorCallback())
    }
  }

  const updateCompanyGitConfiguration = (id: number, repository: string, username: string, password: string, successCallback: () => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putCompanyGitConfiguration(id, username, repository, password, withCredentialsRetries).then(result => {
        setCompany(result.data)
      }).catch(error => errorCallback())
    }
  }

  const createIdentity = (name: string, password: string, roles: RoleResource[]) => {
    if (environment) {
      environment.launchpadSUControllerApi.createIdentity(name, password, roles.map(role => role.id!), withCredentialsRetries).then(_result => {
        setIscreateIdentityDialogOpen(false);
        window.location.reload()
      })
    }
  }

  const createInvite = (projectId: number, email: string, success: (result: InviteResource) => void, error: (result: string) => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.postInvite(projectId, email, language, "launchpad.invite.register", "launchpad.invite.accept", "noreply@kodo-tech.com", "https://www.kodo-tech.com/register", "https://chef.kodo-tech.com", withCredentialsRetries).then(inviteResult => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
          success(inviteResult.data)
        }).catch(error => error(error.toString()))
      }).catch((error) => {
        error(error.toString())
      })
    }
  }

  const createCompanyInvite = (companyId: number, email: string, success: (result: InviteResource) => void, error: (result: string) => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.postCompanyInvite(companyId, email, language, "launchpad.invite.register", "launchpad.invite.accept", "noreply@kodo-tech.com", "https://www.kodo-tech.com/register", "https://chef.kodo-tech.com", withCredentialsRetries).then(inviteResult => {
        success(inviteResult.data)
      }).catch((error) => {
        error(error.toString())
      })
    }
  }

  const updateIdentity = (identity: number, name: string, roles: RoleResource[]) => {
    if (environment) {
      environment.launchpadSUControllerApi.updateIdentity(identity, roles.map(role => role.id!), withCredentialsRetries).then(_result => {
        setIscreateIdentityDialogOpen(false);
        window.location.reload()
      })
    }
  }

  const createRole = (name: string, project: number, isPublic: boolean, privileges: PrivilegeResource[], description: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createRole(project, name, isPublic, privileges.map(privilege => privilege.id!), description, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateRole = (role: number, name: string, isPublic: boolean, privileges: PrivilegeResource[], description: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateRole(role, name, isPublic, privileges.map(privilege => privilege.id!), description, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const createService = (project: number, name: string, description: string, dependencies: number[] | undefined, otherDependencies: number[] | undefined, type: ServiceResourceTypeEnum) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createService(project, name, description, type, dependencies, otherDependencies, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const deleteService = (id: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteService(id, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateService = (service: number, name: string, description: string, dependencies: number[] | undefined, otherDependencies: number[] | undefined, type: ServiceResourceTypeEnum) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateService(service, name, description, type, dependencies, otherDependencies, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const createPrivileges = (names: string[], project: number) => {
    return names.length != 0 ? Environment.getInstance().then((environment) => {
      environment.launchpadAdminControllerApi.createPrivileges(project, names, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }) : Promise.resolve()
  }

  const createMail = (name: string, description: string, footer: string | undefined, callback?: (template: MailResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.postMail(name, description, footer, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const updateMail = (id: number, name: string, description: string, footer: string | undefined, callback?: (template: MailResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.putMail(id, name, description, footer, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const deleteMail = (id: number, callback?: () => void) => {
    if (environment) {
      environment.mailAdminControllerApi.deleteMail(id, withCredentialsRetries).then(() => {
        callback?.call(this)
      })
    }
  }

  const createMailTemplate = (mailId: number, subject: string, body: string, language: string, callback?: (template: MailResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.postMailTemplate(mailId, language, subject, body, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const updateMailTemplate = (id: number, subject: string, body: string, language: string, callback?: (template: MailResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.putMailTemplate(id, language, subject, body, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const deleteMailTemplate = (id: number, callback?: (mail: MailResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.deleteMailTemplate(id, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const createFooter = (name: string, description: string, callback?: (template: FooterResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.postFooter(name, description, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const updateFooter = (id: number, name: string, description: string, callback?: (template: FooterResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.putFooter(id, name, description, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const deleteFooter = (id: number, callback?: () => void) => {
    if (environment) {
      environment.mailAdminControllerApi.deleteFooter(id, withCredentialsRetries).then(() => {
        callback?.call(this)
      })
    }
  }

  const createFooterTemplate = (footerId: number, language: string, body: string, callback?: (template: FooterResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.postFooterTemplate(footerId, language, body, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const updateFooterTemplate = (id: number, language: string, body: string, callback?: (template: FooterResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.putFooterTemplate(id, language, body, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const deleteFooterTemplate = (id: number, callback?: (footer: FooterResource) => void) => {
    if (environment) {
      environment.mailAdminControllerApi.deleteFooterTemplate(id, withCredentialsRetries).then(result => {
        callback?.call(this, result.data)
      })
    }
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  const createRelease = (targetEnvironment: number, major: number, minor: number, patch: number, cause: string, recipes: string[], success: (result: ReleaseResource) => void, error: (result: string) => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.postRelease(targetEnvironment, major, minor, patch, cause, recipes, withCredentialsRetries).then(result => {
        success(result.data)
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      }).catch((result) => {
        error(result.response!.status)
      })
    }
  }

  const promoteRelease = (releaseId: number, environmentId: number, success: (result: ReleaseResource) => void, error: (result: string) => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.promoteRelease(releaseId, environmentId, withCredentialsRetries).then(result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
        success(result.data)
      }).catch((result) => {
        error(result.response!.status)
      })
    }
  }

  const downloadRecipe = (project: number, recipes: string[], successCallback: () => void, errorCallback: (result: string) => void) => {
    if (environment) {

      const filename = "recipe.zip"

      environment.launchpadAdminControllerApi.getRecipe(project, recipes, { withCredentials: true, responseType: 'blob' }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        successCallback();
      }).catch(error => {
        errorCallback(error.message)
      });
    }
  }

  const verifyRecipe = (project: number, recipes: string[], successCallback: (result: string) => void, errorCallback: (result: string) => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.verifyRecipe(project, recipes, withCredentialsRetries).then((result) => {
        successCallback(result.data)
      }).catch((error) => {
        errorCallback(error.response!.status)
      })
    }
  }

  const updateProjectRecipes = (project: number, recipes: string[], successCallback: () => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putProjectRecipes(project, recipes, withCredentialsRetries).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
        successCallback()
      }).catch((error) => {
        errorCallback()
      })
    }
  }

  const createEnvironmentVariable = (serviceId: number, key: string, value: string | undefined, secretId: number | undefined, parameterId: number | undefined, isExpression: boolean) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createEnvironmentVariable(serviceId, key, value, secretId, parameterId, isExpression, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateEnvironmentVariable = (id: number, key: string, value: string | undefined, secretId: number | undefined, parameterId: number | undefined, isExpression: boolean) => {
    Environment.getInstance().then((environment) => {
      environment.launchpadAdminControllerApi.updateEnvironmentVariable(id, key, value, secretId, parameterId, isExpression, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    })
  }

  const deleteEnvironmentVariable = (id: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteEnvironmentVariable(id, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const deleteEnvironment = (environmentId: number, successCallback: () => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteEnvironment(environmentId, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
          successCallback()
        })
      }).catch((_error) => {
        errorCallback()
      })
    }
  }

  const createEnvironment = (projectId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.postEnvironment(projectId, name, domain, precedentEnvironmentId, tags, withCredentialsRetries).then(result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((projectsResult) => {
          setProjects(projectsResult.data)
          successCallback(result.data)
        })
      }).catch((_error) => {
        errorCallback()
      })
    }
  }

  const updateEnvironment = (environmentId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.putEnvironment(environmentId, name, domain, precedentEnvironmentId, tags, withCredentialsRetries).then(result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((projectsResult) => {
          setProjects(projectsResult.data)
          successCallback(result.data)
        })
      }).catch((_error) => {
        errorCallback()
      })
    }
  }

  const createParameter = (serviceId: number, key: string, value: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createParameter(serviceId, key, value, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateParameter = (id: number, key: string, value: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateParameter(id, key, value, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const deleteParameter = (id: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteParameter(id, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const createSecret = (serviceId: number, key: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createSecret(serviceId, key, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateSecret = (id: number, key: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateSecret(id, key, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const deleteSecret = (id: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteSecret(id, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const createVolume = (serviceId: number, name: string, mount: string, size: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.createVolume(serviceId, name, size, mount, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const updateVolume = (id: number, name: string, mount: string, size: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.updateVolume(id, name, size, mount, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const deleteVolume = (id: number) => {
    if (environment) {
      environment.launchpadAdminControllerApi.deleteVolume(id, withCredentialsRetries).then(_result => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const uploadFavIcon = (projectId: number, file: File) => {
    if (environment) {
      const data = new FormData()
      data.append("file", file)

      const config: RetryConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        retried: false
      };

      axios.post(environment.launchpadBasePath + "/admin/project/" + projectId + "/favicon", data, config).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }


  const uploadAppleTouchIcon = (projectId: number, file: File) => {
    if (environment) {
      const data = new FormData()
      data.append("file", file)

      const config: RetryConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        retried: false
      };

      axios.post(environment.launchpadBasePath + "/admin/project/" + projectId + "/appletouchicon", data, config).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const uploadIcon = (projectId: number, file: File) => {
    if (environment) {
      const data = new FormData()
      data.append("file", file)

      const config: RetryConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        retried: false
      };

      axios.post(environment.launchpadBasePath + "/admin/project/" + projectId + "/icon", data, config).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const uploadMaskableIcon = (projectId: number, file: File) => {
    if (environment) {
      const data = new FormData()
      data.append("file", file)

      const config: RetryConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        retried: false
      };

      axios.post(environment.launchpadBasePath + "/admin/project/" + projectId + "/maskableicon", data, config).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const uploadLogo = (projectId: number, file: File) => {
    if (environment) {
      const data = new FormData()
      data.append("file", file)

      const config: RetryConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        retried: false
      };

      axios.post(environment.launchpadBasePath + "/admin/project/" + projectId + "/logo", data, config).then((result) => {
        environment.launchpadAdminControllerApi.getUserProjects(withCredentialsRetries).then((result) => {
          setProjects(result.data)
        })
      })
    }
  }

  const answerMessage = (id: number, message: string) => {
    if (environment) {
      environment.launchpadAdminControllerApi.postAnswer(id, message, withCredentialsRetries).then((_result) => {
        environment.launchpadAdminControllerApi.getMessages(withCredentialsRetries).then((result) => {
          setMessages(result.data)
        })
      })
    }
  }

  const getConsensus = (releaseId: number, success: (consensus: ConsensusResource) => void, error: () => void) => {
    if (environment) {
      environment.launchpadAdminControllerApi.getReleaseConsensus(releaseId, withCredentialsRetries).then((result) => {
        success(result.data)
      })
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {links.find(link => link.path === location.pathname)?.name}
          </Typography>

          <div style={{ marginLeft: 'auto' }}>
            <Stack direction='row' spacing={1}>
              {links.find(link => link.path === location.pathname)?.actions.map(entry =>
                <Button disabled={entry.disabled} key={entry.name} onClick={entry.action} variant="contained" color='secondary'>
                  <FormattedMessage id={entry.name} />
                </Button>)}

            </Stack>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {list()}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box
          component="main"
          flexGrow={1}>
          <Routes>
            {links.filter(link => link.display).map(link => <Route key={link.name} path={link.path} element={
              <Root createIdentity={createIdentity}
                updateIdentity={updateIdentity}
                updateRole={updateRole}
                createPrivileges={createPrivileges}
                createRole={createRole}
                isAuthenticated={isAuthenticated}
                component={link.component}
                projects={projects}
                environment={environment}
                company={company}
                companyProjects={companyProjects}
                user={user}
                updateService={updateService}
                createService={createService}
                deleteService={deleteService}
                createRelease={createRelease}
                promoteRelease={promoteRelease}
                createEnvironmentVariable={createEnvironmentVariable}
                updateEnvironmentVariable={updateEnvironmentVariable}
                deleteEnvironmentVariable={deleteEnvironmentVariable}
                createParameter={createParameter}
                updateParameter={updateParameter}
                deleteParameter={deleteParameter}
                createSecret={createSecret}
                updateSecret={updateSecret}
                deleteSecret={deleteSecret}
                createVolume={createVolume}
                updateVolume={updateVolume}
                deleteVolume={deleteVolume}
                createProject={createProject}
                updateProject={updateProject}
                uploadFavIcon={uploadFavIcon}
                uploadAppleTouchIcon={uploadAppleTouchIcon}
                uploadLogo={uploadLogo}
                uploadIcon={uploadIcon}
                uploadMaskableIcon={uploadMaskableIcon}
                createMail={createMail}
                updateMail={updateMail}
                createFooter={createFooter}
                updateFooter={updateFooter}
                footers={footers}
                languages={languages}
                createMailTemplate={createMailTemplate}
                updateMailTemplate={updateMailTemplate}
                createFooterTemplate={createFooterTemplate}
                updateFooterTemplate={updateFooterTemplate}
                deleteFooterTemplate={deleteFooterTemplate}
                deleteMailTemplate={deleteMailTemplate}
                deleteMail={deleteMail}
                deleteFooter={deleteFooter}
                identity={identity}
                downloadRecipe={downloadRecipe}
                verifyRecipe={verifyRecipe}
                createInvite={createInvite}
                createCompanyInvite={createCompanyInvite}
                deleteEnvironment={deleteEnvironment}
                createEnvironment={createEnvironment}
                updateEnvironment={updateEnvironment}
                updateProjectGitConfiguration={updateProjectGitConfiguration}
                createTags={createTags}
                allRoles={allRoles}
                servicePropertyTypes={servicePropertyTypes}
                messages={messages}
                answerMessage={answerMessage}
                updateCompanyGitConfiguration={updateCompanyGitConfiguration}
                updateProjectRecipes={updateProjectRecipes}
                recipes={recipes}
                getConsensus={getConsensus} />}
            />)}

            <Route path="/onboarding" element={<UserOnboarding user={user} activateUser={activateUser} activateCompanyUser={activateCompanyUser} company={company} />} />

          </Routes>
        </Box>

        <Project user={user} environment={environment} uploadMaskableIcon={uploadMaskableIcon} uploadFavIcon={uploadFavIcon} uploadAppleTouchIcon={uploadAppleTouchIcon} uploadLogo={uploadLogo} uploadIcon={uploadIcon} isOpen={isProjectDialogOpen} createProject={createProject} updateProject={updateProject} selectedProject={undefined} closeDialog={() => setIsProjectDialogOpen(false)} deleteEnvironment={deleteEnvironment} createEnvironment={createEnvironment} updateEnvironment={updateEnvironment} updateProjectGitConfiguration={updateProjectGitConfiguration} createTags={createTags} />
        <CreateEditAdmin identity={undefined} roles={allRoles} isOpen={iscreateIdentityDialogOpen} createIdentity={createIdentity} updateIdentity={updateIdentity} closeDialog={() => setIscreateIdentityDialogOpen(false)} />

        <ErrorHandler />
      </Main>


    </Box>

  );
}

export default App
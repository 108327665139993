import React from 'react';
import axios from 'axios'

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type ErrorHandlerProps = {
}

const ErrorHandler = ({}: ErrorHandlerProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleError = (error: any) => {
    if (error?.response?.status < 400 || error?.response?.status >= 500 ) {
      setOpen(true)
      setMessage(error.toString())
    }
  }

  // handle all non 401 errors
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    handleError(error)
    return Promise.reject(error);;
  });

  return (
    <Snackbar open={open} autoHideDuration={6000} onAbort={() => setOpen(false)} onClose={() => setOpen(false)}>
      <div>
        <MuiAlert elevation={6} variant="filled" severity='error'>{message}</MuiAlert>
      </div>
    </Snackbar>
  );
}

export default ErrorHandler;
import React from 'react';
import { IconButton, Typography, Dialog, Toolbar, Button, TextField, Box, Stack, Tabs, Tab } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close';
import ContentContainer from '../ContentContainer/ContentContainer'
import { EnvironmentResource, GitConfigurationResource, ProjectResource, UserResource } from '../generated/launchpad-api';
import ColorPicker from './ColorPicker'
import { Environment } from '../Environment';
import LogoSelector from './LogoSelector';
import { DataGrid, GridColDef, GridCellParams, GridOverlay } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../react-confirm-alert.css'
import CreateEditEnvironment from '../CreateEditEnvironment/CreateEditEnvironment';
import EnvironmentsDiagram from '../CreateEditEnvironment/EnvironmentsDiagram';
import { ReactFlowProvider } from 'reactflow';

type CreateEditProjectProps = {
    isOpen: boolean,
    user: UserResource | undefined
    closeDialog: () => void,
    createProject: (name: string, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => void,
    updateProject: (id: number, description: string, adminUrl: string | undefined, shortname: string | undefined, themeColor: string | undefined, themeBackgroundColor: string | undefined) => void,
    selectedProject: ProjectResource | undefined,
    uploadFavIcon: (id: number, file: File) => void,
    uploadAppleTouchIcon: (id: number, file: File) => void,
    uploadLogo: (id: number, file: File) => void,
    uploadIcon: (id: number, file: File) => void,
    uploadMaskableIcon: (id: number, file: File) => void,

    environment: Environment | undefined,

    deleteEnvironment: (environmentId: number, successCallback: () => void, errorCallback: () => void ) => void,

    createEnvironment: (projectId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,
    updateEnvironment: (environmentId: number, name: string, precedentEnvironmentId: number | undefined, domain: string, tags: number[], successCallback: (environment: EnvironmentResource) => void, errorCallback: () => void) => void,

    updateProjectGitConfiguration: (id: number, repository: string, username: string, password: string, successCallback: () => void, errorCallback: () => void) => void,
    createTags: (names: string[], project: number) => void,
}

const CreateEditProject = ({ isOpen, selectedProject, environment, user, closeDialog, createProject, updateProject, uploadFavIcon, uploadAppleTouchIcon, uploadLogo, uploadIcon, uploadMaskableIcon, createEnvironment, updateEnvironment, deleteEnvironment, updateProjectGitConfiguration, createTags }: CreateEditProjectProps) => {
    const intl = useIntl()

    const [name, setName] = React.useState<string | undefined>(undefined)
    const [shortName, setShortName] = React.useState<string | undefined>(undefined)
    const [description, setDescription] = React.useState<string | undefined>(undefined)
    const [adminUrl, setAdminUrl] = React.useState<string | undefined>(undefined)
    const [saveEnabled, setSaveEnabled] = React.useState<boolean>(false)
    const [themeColor, setThemeColor] = React.useState<string | undefined>(undefined)
    const [themeBackgroundColor, setThemeBackgroundColor] = React.useState<string | undefined>(undefined)
    const [tabIndex, setTabIndex] = React.useState<number>(0)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState<EnvironmentResource | undefined>(undefined)
    const [isCreateEditEnvironmentDialogOpen, setIsCreateEditEnvironmentDialogOpen] = React.useState<boolean>(false)
    const [pageSizeEnvironment, setPageSizeEnvironment] = React.useState<number>(5);

    const diagram = React.useRef<HTMLDivElement | undefined>(undefined)

    const [gitPassword, setGitPassword] = React.useState<string | undefined>(selectedProject?.gitConfiguration?.password)
    const [gitRepository, setGitRepository] = React.useState<string | undefined>(selectedProject?.gitConfiguration?.repository)
    const [gitUserName, setGitUserName] = React.useState<string | undefined>(selectedProject?.gitConfiguration?.username)
    const [saveGitConfigurationEnabled, setSaveGitConfigurationEnabled] = React.useState<boolean>(false)

    const columnsEnvironmentResources: GridColDef[] = [
        { field: 'name', headerName: intl.formatMessage({ id: 'root.grid.environment.name' }), width: 300 },
        { field: 'gitRepository', headerName: intl.formatMessage({ id: 'root.grid.environment.repository' }), flex: 300 },
        {
            field: 'id', headerName: intl.formatMessage({ id: 'root.grid.environment.actions' }), width: 130, renderCell: (params: GridCellParams) => (
                <Stack direction="row" spacing={2}>
                    <IconButton disabled={user?.subscription?.subscriptionDisabled} edge="end" aria-label="edit" onClick={() => {
                        setSelectedEnvironment(params.row as EnvironmentResource)
                        setIsCreateEditEnvironmentDialogOpen(true)
                    }}>
                        <EditIcon />
                    </IconButton>

                    <IconButton edge="end" aria-label="edit" disabled={user?.subscription?.subscriptionDisabled || !(params.row as EnvironmentResource).canDelete} onClick={() => {
                        confirmAlert({
                            overlayClassName: "react-confirm-alert-overlay-top",
                            title: intl.formatMessage({ id: 'root.grid.environment.actions.delete' }),
                            message: intl.formatMessage({ id: 'root.grid.environment.actions.delete.message' }),
                            buttons: [
                                {
                                    label: intl.formatMessage({ id: 'confirmation.dialog.confirm' }),
                                    onClick: () => {
                                        deleteEnvironment(Number.parseInt(params.value), () => {}, () => {})
                                    }
                                },
                                {
                                    label: intl.formatMessage({ id: 'confirmation.dialog.cancel' }),
                                    onClick: () => {
                                        // nothing to do
                                    }
                                }
                            ]
                        });

                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const handleClickClose = () => {
        closeDialog()
    }

    const stringChanged = (src: string | undefined, origin: string | undefined) => {
        return src && src.trim().length !== 0 && src !== origin
    }

    React.useEffect(() => {
        if ((name?.trim().length ?? 0 > 0) 
            && (shortName?.trim().length ?? 0 > 0)
            && (description?.trim().length ?? 0 > 0)
            && (adminUrl !== selectedProject?.adminUrl || name !== selectedProject?.name || description !== selectedProject?.description)) {
            setSaveEnabled(true)
        } else {
            setSaveEnabled(false)
        }
    }, [name, description, adminUrl])

    React.useEffect(() => {
        if (selectedProject
            && gitPassword
            && gitRepository
            && gitUserName
            && (stringChanged(gitPassword, selectedProject.gitConfiguration?.password)
            || stringChanged(gitRepository, selectedProject.gitConfiguration?.repository)
            || stringChanged(gitUserName, selectedProject.gitConfiguration?.username))) {
            setSaveGitConfigurationEnabled(true)
        } else {
            setSaveGitConfigurationEnabled(false)
        }
    }, [gitPassword, gitRepository, gitUserName, selectedProject])

    React.useEffect(() => {
        if (selectedProject && isOpen) {
            setName(selectedProject.name)
            setShortName(selectedProject.shortName ?? undefined)
            setAdminUrl(selectedProject.adminUrl)
            setDescription(selectedProject.description)
            setThemeColor(selectedProject.themeColor ?? undefined)
            setThemeBackgroundColor(selectedProject.themeBackgroundColor ?? undefined)
            setGitPassword(selectedProject.gitConfiguration?.password)
            setGitRepository(selectedProject.gitConfiguration?.repository)
            setGitUserName(selectedProject.gitConfiguration?.username)

        }
    }, [selectedProject, isOpen])

    React.useEffect(() => {
        if (!isOpen) {
            setName(undefined)
            setShortName(undefined)
            setAdminUrl(undefined)
            setDescription(undefined)
            setThemeColor(undefined)
            setThemeBackgroundColor(undefined)
            setGitPassword(undefined)
            setGitRepository(undefined)
            setGitUserName(undefined)

        }
    }, [isOpen])

    const handleClickSave = () => {
        if (name && description && name.trim().length !== 0) {
            if(selectedProject) {
                updateProject(selectedProject.id!, description, adminUrl, shortName, themeColor, themeBackgroundColor)
            } else {
                createProject(name, description, adminUrl, shortName, themeColor, themeBackgroundColor)
            }
            closeDialog()
        }
    }

    const handleClickSaveGitConfiguration = () => {
        if (selectedProject && gitRepository && gitUserName && gitPassword) {
            updateProjectGitConfiguration(selectedProject.id!, gitRepository, gitUserName, gitPassword, () => {}, () => {})
        }
    }

    const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };
    

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClickClose}>
            <Toolbar>
                <Typography variant="h6">
                    <FormattedMessage id='project.dialog.title' />
                </Typography>

                <Stack spacing={2} direction='row' style={{ marginLeft: 'auto' }}>
                    <Button
                        style={{ display: tabIndex === 0 ? "block" : 'none' }}
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={handleClickSave}
                        disabled={user?.subscription?.subscriptionDisabled || !saveEnabled}>
                        <FormattedMessage id="project.save.button" />
                    </Button>

                    <Button
                        style={{ display: tabIndex === 1 ? "block" : 'none' }}
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={handleClickSaveGitConfiguration}
                        disabled={user?.subscription?.subscriptionDisabled || !saveGitConfigurationEnabled}>
                        <FormattedMessage id="project.save.git.configuration" />
                    </Button>

                    <IconButton color="inherit" onClick={handleClickClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Toolbar>

            <ContentContainer backgroundColor='white' >
                
                <Tabs value={tabIndex} onChange={handleTabsChange} textColor="inherit"
                    indicatorColor="secondary">
                    <Tab key={0} label={intl.formatMessage({ id: "project.information" })} />
                    <Tab key={1} label={intl.formatMessage({ id: "project.git.configuration" })} disabled={selectedProject === undefined} />
                    <Tab key={2} label={intl.formatMessage({ id: "project.environments" })} disabled={selectedProject === undefined} />
                    <Tab key={3} label={intl.formatMessage({ id: "project.theme" })} disabled={selectedProject === undefined} style={{ display: user?.identityResource?.superUser ? "block" : "none" }} />
                </Tabs>

                <form noValidate={true} autoComplete="off" style={{display: tabIndex === 0 ? "block": 'none', marginTop: '1rem'}}>
                    <Typography variant='body1'>
                        <FormattedMessage id='project.dialog.description' />
                    </Typography>

                    <TextField
                        required
                        disabled={selectedProject !== undefined}
                        error={false}
                        fullWidth={true}
                        id="name"
                        type="text"
                        value={name || ''}
                        label={intl.formatMessage({ id: "project.name" })}
                        placeholder={intl.formatMessage({ id: "project.name.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setName(event.target.value) }}
                    />

                    <TextField
                        error={false}
                        fullWidth={true}
                        id="short-name"
                        type="text"
                        value={shortName || ''}
                        label={intl.formatMessage({ id: "project.short.name" })}
                        placeholder={intl.formatMessage({ id: "project.short.name.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setShortName(event.target.value) }}
                    />

                    <TextField
                        required
                        multiline
                        error={false}
                        fullWidth={true}
                        id="description"
                        type="text"
                        value={description || ''}
                        label={intl.formatMessage({ id: "project.description" })}
                        placeholder={intl.formatMessage({ id: "project.description.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setDescription(event.target.value) }}
                    />

                    <TextField
                        error={false}
                        fullWidth={true}
                        id="admin-url"
                        type="text"
                        value={adminUrl || ''}
                        label={intl.formatMessage({ id: "project.admin.url" })}
                        placeholder={intl.formatMessage({ id: "project.admin.url.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setAdminUrl(event.target.value) }}
                    />
                </form>

                <form noValidate={true} autoComplete="off" style={{ display: tabIndex === 1 ? "block" : 'none', marginTop: '1rem' }}>
                    <TextField
                        required
                        error={false}
                        fullWidth={true}
                        id="gitRepository"
                        type="text"
                        value={gitRepository || ''}
                        label={intl.formatMessage({ id: "create.project.environment.repository" })}
                        placeholder={intl.formatMessage({ id: "create.project.environment.repository.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setGitRepository(event.target.value) }}
                    />

                    <TextField
                        required
                        error={false}
                        fullWidth={true}
                        id="gitUserName"
                        type="text"
                        value={gitUserName || ''}
                        label={intl.formatMessage({ id: "create.project.environment.username" })}
                        placeholder={intl.formatMessage({ id: "create.project.environment.username.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setGitUserName(event.target.value) }}
                    />

                    <TextField
                        required
                        error={false}
                        fullWidth={true}
                        id="gitPassword"
                        type="password"
                        value={gitPassword || ''}
                        label={intl.formatMessage({ id: "create.project.environment.password" })}
                        placeholder={intl.formatMessage({ id: "create.project.environment.password.placeholder" })}
                        margin="normal"
                        onChange={(event) => { setGitPassword(event.target.value) }}
                    />

                </form>

                <div style={{ display: tabIndex === 2 ? "block" : 'none', marginTop: '1rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant='h5'>
                            <FormattedMessage id='project.environments.title' />
                        </Typography>

                        <IconButton color="primary" aria-label="create service" component="label" onClick={() => { setIsCreateEditEnvironmentDialogOpen(true) }}>
                            <AddCircleIcon />
                        </IconButton>

                    </Box>

                    <DataGrid
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        components={{ NoRowsOverlay: () => <GridOverlay><Typography><FormattedMessage id="datagrid.no.rows" /></Typography></GridOverlay> }}
                        columns={columnsEnvironmentResources}
                        rows={selectedProject?.environments ?? []}
                        pageSize={pageSizeEnvironment}
                        rowsPerPageOptions={[5, 10, 20]}
                        onPageSizeChange={(newPageSize) => setPageSizeEnvironment(newPageSize)}
                        autoHeight={true}
                        disableSelectionOnClick />

                    <ReactFlowProvider>
                        <Box ref={diagram} style={{ background: 'white' }} >
                            <EnvironmentsDiagram environments={selectedProject?.environments} selectedEnvironment={undefined} fullscreen={() => diagram.current?.requestFullscreen()} windowed={() => document.exitFullscreen()} />
                        </Box>
                    </ReactFlowProvider>

                </div>

                <form noValidate={true} autoComplete="off" style={{ display: tabIndex === 3 ? "block" : 'none', marginTop: '1rem' }}>
                    <Stack spacing={2}>
                        <Typography variant='body1'>
                            <FormattedMessage id='project.theme.hint' />
                        </Typography>
                        <ColorPicker id='project.theme.color' toolTipId='project.theme.color.tooltip' color={themeColor} onChange={(color) => setThemeColor(color.hex)} />
                        <ColorPicker id='project.theme.background.color' toolTipId='project.theme.background.color.tooltip' color={themeBackgroundColor} onChange={(color) => setThemeBackgroundColor(color.hex)} />
                        <LogoSelector id='project.fav.icon' toolTipId='project.fav.icon.tooltip' accept='image/png,image/gif,image/x-icon,image/vnd.microsoft.icon' path={environment && selectedProject?.favIcon?.path ? environment.launchpadBasePath + selectedProject?.favIcon?.path : undefined} uploadIcon={uploadFavIcon} project={selectedProject} />
                        <LogoSelector id='project.appletouch.icon' toolTipId='project.appletouch.icon.tooltip' accept='image/png' path={environment && selectedProject?.appleTouchIcon?.path ? environment?.launchpadBasePath + selectedProject?.appleTouchIcon?.path : undefined} uploadIcon={uploadAppleTouchIcon} project={selectedProject} />
                        <LogoSelector id='project.logo' toolTipId='project.logo.tooltip' accept='image/svg+xml' minWidth='20rem' path={environment && selectedProject?.logo?.path ? environment?.launchpadBasePath + selectedProject?.logo?.path : undefined} uploadIcon={uploadLogo} project={selectedProject} />
                        <LogoSelector id='project.icon' toolTipId='project.icon.tooltip' accept='image/svg+xml' minWidth='20rem' path={environment && selectedProject?.icon?.path ? environment?.launchpadBasePath + selectedProject?.icon?.path : undefined} uploadIcon={uploadIcon} project={selectedProject} />
                        <LogoSelector id='project.maskable.icon' toolTipId='project.maskable.icon.tooltip' accept='image/svg+png' path={environment && selectedProject?.maskableIcon?.path ? environment?.launchpadBasePath + selectedProject?.maskableIcon?.path : undefined} uploadIcon={uploadMaskableIcon} project={selectedProject} />
                    </Stack>
                </form>

                
            </ContentContainer>

            <CreateEditEnvironment user={user} selectedProject={selectedProject} isOpen={isCreateEditEnvironmentDialogOpen} selectedEnvironment={selectedEnvironment} closeDialog={() => {
                setIsCreateEditEnvironmentDialogOpen(false)
                setSelectedEnvironment(undefined)}
            } createEnvironment={createEnvironment} updateEnvironment={updateEnvironment}
            createTags={createTags} />

        </Dialog>
    );
}


export default CreateEditProject;
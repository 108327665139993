/* tslint:disable */
/* eslint-disable */
/**
 * Springboot Template API
 * Documentation springboot template Service API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface FooterResource
 */
export interface FooterResource {
    /**
     * 
     * @type {number}
     * @memberof FooterResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FooterResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterResource
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterResource
     */
    'description'?: string;
    /**
     * 
     * @type {Array<FooterTemplateResource>}
     * @memberof FooterResource
     */
    'templates'?: Array<FooterTemplateResource>;
    /**
     * 
     * @type {boolean}
     * @memberof FooterResource
     */
    'deletable'?: boolean;
}
/**
 * 
 * @export
 * @interface FooterTemplateResource
 */
export interface FooterTemplateResource {
    /**
     * 
     * @type {number}
     * @memberof FooterTemplateResource
     */
    'id'?: number;
    /**
     * 
     * @type {LanguageResource}
     * @memberof FooterTemplateResource
     */
    'language'?: LanguageResource;
    /**
     * 
     * @type {string}
     * @memberof FooterTemplateResource
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface LanguageResource
 */
export interface LanguageResource {
    /**
     * 
     * @type {number}
     * @memberof LanguageResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageResource
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'href'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    'templated'?: boolean;
}
/**
 * 
 * @export
 * @interface MailResource
 */
export interface MailResource {
    /**
     * 
     * @type {number}
     * @memberof MailResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailResource
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailResource
     */
    'description'?: string;
    /**
     * 
     * @type {FooterResource}
     * @memberof MailResource
     */
    'footer'?: FooterResource;
    /**
     * 
     * @type {Array<MailTemplateResource>}
     * @memberof MailResource
     */
    'templates'?: Array<MailTemplateResource>;
}
/**
 * 
 * @export
 * @interface MailTemplateResource
 */
export interface MailTemplateResource {
    /**
     * 
     * @type {number}
     * @memberof MailTemplateResource
     */
    'id'?: number;
    /**
     * 
     * @type {LanguageResource}
     * @memberof MailTemplateResource
     */
    'language'?: LanguageResource;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateResource
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateResource
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface PageFooterResource
 */
export interface PageFooterResource {
    /**
     * 
     * @type {number}
     * @memberof PageFooterResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFooterResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFooterResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFooterResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFooterResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<FooterResource>}
     * @memberof PageFooterResource
     */
    'content'?: Array<FooterResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageFooterResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageFooterResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFooterResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFooterResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFooterResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMailResource
 */
export interface PageMailResource {
    /**
     * 
     * @type {number}
     * @memberof PageMailResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<MailResource>}
     * @memberof PageMailResource
     */
    'content'?: Array<MailResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageMailResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMailResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}

/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/prometheus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthPath(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthPath(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.info(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async links(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Link; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.links(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prometheus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prometheus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<object> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath(options?: any): AxiosPromise<object> {
            return localVarFp.healthPath(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<object> {
            return localVarFp.info(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Link; }; }> {
            return localVarFp.links(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus(options?: any): AxiosPromise<object> {
            return localVarFp.prometheus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * 
     * @summary Actuator web endpoint \'health\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public health(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'health-path\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public healthPath(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).healthPath(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'info\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public info(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator root web endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public links(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).links(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'prometheus\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public prometheus(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).prometheus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFooter: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFooter', 'id', id)
            const localVarPath = `/admin/footer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFooterTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFooterTemplate', 'id', id)
            const localVarPath = `/admin/footertemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMail', 'id', id)
            const localVarPath = `/admin/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMailTemplate', 'id', id)
            const localVarPath = `/admin/mailtemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/footer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFooter: async (name: string, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('postFooter', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('postFooter', 'description', description)
            const localVarPath = `/admin/footer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFooterTemplate: async (id: number, language: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postFooterTemplate', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('postFooterTemplate', 'language', language)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postFooterTemplate', 'body', body)
            const localVarPath = `/admin/footer/{id}/template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMail: async (name: string, description: string, footer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('postMail', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('postMail', 'description', description)
            const localVarPath = `/admin/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (footer !== undefined) {
                localVarQueryParameter['footer'] = footer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate: async (id: number, language: string, subject: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postMailTemplate', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('postMailTemplate', 'language', language)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('postMailTemplate', 'subject', subject)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postMailTemplate', 'body', body)
            const localVarPath = `/admin/mail/{id}/template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFooter: async (id: number, name: string, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFooter', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('putFooter', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('putFooter', 'description', description)
            const localVarPath = `/admin/footer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFooterTemplate: async (id: number, language: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFooterTemplate', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('putFooterTemplate', 'language', language)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putFooterTemplate', 'body', body)
            const localVarPath = `/admin/footertemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMail: async (id: number, name: string, description: string, footer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMail', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('putMail', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('putMail', 'description', description)
            const localVarPath = `/admin/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (footer !== undefined) {
                localVarQueryParameter['footer'] = footer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailFooter: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMailFooter', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('putMailFooter', 'name', name)
            const localVarPath = `/admin/mail/{id}/footer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate: async (id: number, language: string, subject: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMailTemplate', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('putMailTemplate', 'language', language)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('putMailTemplate', 'subject', subject)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putMailTemplate', 'body', body)
            const localVarPath = `/admin/mailtemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMail: async (name: string, language: string, from: string, to: string, context?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('testMail', 'name', name)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('testMail', 'language', language)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('testMail', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('testMail', 'to', to)
            const localVarPath = `/admin/testMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFooter(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFooter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFooterTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFooterTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFooterPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFooterPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFooter(name: string, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFooter(name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFooterTemplate(id: number, language: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFooterTemplate(id, language, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMail(name: string, description: string, footer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMail(name, description, footer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailTemplate(id: number, language: string, subject: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTemplate(id, language, subject, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFooter(id: number, name: string, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFooter(id, name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFooterTemplate(id: number, language: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFooterTemplate(id, language, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMail(id: number, name: string, description: string, footer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMail(id, name, description, footer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailFooter(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailFooter(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailTemplate(id: number, language: string, subject: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailTemplate(id, language, subject, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMail(name: string, language: string, from: string, to: string, context?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMail(name, language, from, to, context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFooter(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteFooter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFooterTemplate(id: number, options?: any): AxiosPromise<FooterResource> {
            return localVarFp.deleteFooterTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate(id: number, options?: any): AxiosPromise<MailResource> {
            return localVarFp.deleteMailTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFooterResource> {
            return localVarFp.getFooterPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any): AxiosPromise<Array<LanguageResource>> {
            return localVarFp.getLanguages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMailResource> {
            return localVarFp.getMailPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFooter(name: string, description: string, options?: any): AxiosPromise<FooterResource> {
            return localVarFp.postFooter(name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFooterTemplate(id: number, language: string, body: string, options?: any): AxiosPromise<FooterResource> {
            return localVarFp.postFooterTemplate(id, language, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMail(name: string, description: string, footer?: string, options?: any): AxiosPromise<MailResource> {
            return localVarFp.postMail(name, description, footer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate(id: number, language: string, subject: string, body: string, options?: any): AxiosPromise<MailResource> {
            return localVarFp.postMailTemplate(id, language, subject, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFooter(id: number, name: string, description: string, options?: any): AxiosPromise<FooterResource> {
            return localVarFp.putFooter(id, name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFooterTemplate(id: number, language: string, body: string, options?: any): AxiosPromise<FooterResource> {
            return localVarFp.putFooterTemplate(id, language, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [footer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMail(id: number, name: string, description: string, footer?: string, options?: any): AxiosPromise<MailResource> {
            return localVarFp.putMail(id, name, description, footer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailFooter(id: number, name: string, options?: any): AxiosPromise<MailResource> {
            return localVarFp.putMailFooter(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} language 
         * @param {string} subject 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate(id: number, language: string, subject: string, body: string, options?: any): AxiosPromise<MailResource> {
            return localVarFp.putMailTemplate(id, language, subject, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMail(name: string, language: string, from: string, to: string, context?: string, options?: any): AxiosPromise<string> {
            return localVarFp.testMail(name, language, from, to, context, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteFooter(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteFooter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteFooterTemplate(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteFooterTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteMail(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteMail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteMailTemplate(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteMailTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getFooterPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getFooterPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getLanguages(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getLanguages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getMailPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getMailPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postFooter(name: string, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postFooter(name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} language 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postFooterTemplate(id: number, language: string, body: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postFooterTemplate(id, language, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} description 
     * @param {string} [footer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postMail(name: string, description: string, footer?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postMail(name, description, footer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} language 
     * @param {string} subject 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postMailTemplate(id: number, language: string, subject: string, body: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postMailTemplate(id, language, subject, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} name 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putFooter(id: number, name: string, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putFooter(id, name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} language 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putFooterTemplate(id: number, language: string, body: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putFooterTemplate(id, language, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} name 
     * @param {string} description 
     * @param {string} [footer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putMail(id: number, name: string, description: string, footer?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putMail(id, name, description, footer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putMailFooter(id: number, name: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putMailFooter(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} language 
     * @param {string} subject 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putMailTemplate(id: number, language: string, subject: string, body: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putMailTemplate(id, language, subject, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} language 
     * @param {string} from 
     * @param {string} to 
     * @param {string} [context] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public testMail(name: string, language: string, from: string, to: string, context?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).testMail(name, language, from, to, context, options).then((request) => request(this.axios, this.basePath));
    }
}



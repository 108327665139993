import { PublicControllerApi, AdminControllerApi, UserControllerApi, SuControllerApi } from './generated/launchpad-api';
import { AdminControllerApi as MailAdminControllerApi } from './generated/mail-api';
import { BASE_PATH as IdentityBasePath } from './generated/launchpad-api/base';
import { BASE_PATH as MailBasePath } from './generated/mail-api/base';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios, { AxiosRequestConfig } from 'axios'

export interface RetryConfig extends AxiosRequestConfig {
    retried: boolean;
}

export const withCredentialsRetries: RetryConfig = {
    retried: false,
    withCredentials: true
};

export class Environment {
   
    private static instance: Environment;
    private constructor(gatewayUrl: string, launchpadBasePath: string, mailBasePath: string) {
        this._gatewayUrl = gatewayUrl
        this._launchpadPublicControllerApi = new PublicControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadUserControllerApi = new UserControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadSUControllerApi = new SuControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadAdminControllerApi = new AdminControllerApi(undefined, launchpadBasePath, axios)

        this._mailAdminControllerApi = new MailAdminControllerApi(undefined, mailBasePath, axios)

        this._launchpadBasePath = launchpadBasePath

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            const { config } = error;
            if (!config || config.retried) {
                if (window.parent && (window.parent !== window)) {
                    window.parent.postMessage({ type: "error", content: JSON.stringify(error?.response) }, document.referrer);
                }
            } else {
                if (error?.response?.status === 401) {
                    config.retried = true
                    // try refresh token
                    return axios.get(this.gatewayUrl + "/refreshtoken", { withCredentials: true }).then(() => {
                        return axios.request(error.config);
                    }).catch(() => {
                        return axios.request(error.config);
                    })
                } else {
                    return Promise.reject(error);
                }
            }
        });
    }

    private _launchpadPublicControllerApi: PublicControllerApi
    private _launchpadAdminControllerApi: AdminControllerApi
    private _launchpadUserControllerApi: UserControllerApi
    private _launchpadSUControllerApi: SuControllerApi
    private _mailAdminControllerApi: MailAdminControllerApi
    private _launchpadBasePath: string
    private _gatewayUrl: string

    public get launchpadPublicControllerApi() {
        return this._launchpadPublicControllerApi;
    }

    public get launchpadAdminControllerApi() {
        return this._launchpadAdminControllerApi;
    }

    public get launchpadUserControllerApi() {
        return this._launchpadUserControllerApi;
    }

    public get launchpadSUControllerApi() {
        return this._launchpadSUControllerApi;
    }

    public get mailAdminControllerApi() {
        return this._mailAdminControllerApi;
    }

    public get launchpadBasePath() {
        return this._launchpadBasePath
    }

    public get gatewayUrl() {
        return this._gatewayUrl
    }

    private initSentry = (sentryDNS: string, sentryEnvironment: string, version: string) => {
        Sentry.init({
            dsn: sentryDNS,
            environment: sentryEnvironment,
            release: version,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }

    public static async getInstance(): Promise<Environment> {
        if (!Environment.instance) {
            return axios.get("/config")
                .then((result) => {
                    Environment.instance = new Environment(result.data.LAUNCHPAD_API_GATEWAY, result.data.LAUNCHPAD_API_GATEWAY + "/launchpad", result.data.LAUNCHPAD_API_GATEWAY + "/mail");

                    if (result.data.SENTRY_DSN && result.data.SENTRY_ENVIRONMENT && process.env.VERSION) {
                        Environment.instance.initSentry(result.data.SENTRY_DSN, result.data.SENTRY_ENVIRONMENT, process.env.VERSION)
                    }

                    return Environment.instance;
                })
                .catch(() => {
                    Environment.instance = new Environment('http://api.dev-yourdomain.com:8082', IdentityBasePath, MailBasePath);
                    return Environment.instance;
                })
        }

        return Environment.instance;
    }
}